import axios from 'axios'
import router from '@/router'
//请求拦截
//给请求设置一些配置
const instance = axios.create({
    baseURL: 'https://www.hyzfjd.cn',
    timeout: 5000
})

//请求拦截(每一次请求之前,都要执行一次这里的命令)
instance.interceptors.request.use(config => {
    // console.log('我在请求之前就执行了');
    let token = localStorage.getItem("token");
    config.headers['Authorization'] = token
    // console.log(config);
    return config
}, err => {
    return Promise.reject(err)
})

//响应拦截
instance.interceptors.response.use(res => {
    // console.log('响应拦截被执行了');
    if (res.data.code == 401) {
        // router.push("/login")
        location.href = '/#/login';
    }
    return res.data;
}, err => {
    return Promise.reject(err)
})

export default instance;