<template>
  <div>
    <el-menu
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      :default-active="activeIndex"
    >
      <template v-for="(item, index) in navlist">
        <el-menu-item
          :key="index"
          v-if="item.adminMenu.menuType == 'page'"
          :index="item.adminMenu.path"
          @click="navclick(item.adminMenu)"
        >
          <i class="el-icon-location"></i>
          <span>{{ item.adminMenu.menuName }}</span>
        </el-menu-item>
        <el-submenu
          :key="index"
          v-if="item.adminMenu.menuType == 'list'"
          :index="item.adminMenu.path"
        >
          <template slot="title">
            <i class="el-icon-menu"></i>
            <span>{{ item.adminMenu.menuName }}</span>
          </template>
          <el-menu-item
            :index="v.adminMenu.path"
            v-for="(v, k) in item.children"
            :key="k"
            @click="navclick(v.adminMenu)"
            >{{ v.adminMenu.menuName }}</el-menu-item
          >
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
import { GetMenuTreeList, Getadmininfo } from "@/request/api";
import axios from "axios";
export default {
  name: "Nav",

  data() {
    return {
      activeIndex: "/",
      grade: "",
      navlist: [],
      list1: [
        {
          adminMenu: {
            id: "1",
            menuName: "首页",
            menuType: "page",
            path: "/",
            icon: null,
          },
          children: [],
        },
        {
          adminMenu: {
            id: "2",
            menuName: "人员",
            menuType: "list",
            path: "1",
            icon: null,
          },
          children: [
            {
              adminMenu: {
                id: "3",
                menuName: "人员管理",
                menuType: "page",
                path: "/tablepersonnel",
                icon: null,
              },
              children: [],
            },
          ],
        },
        {
          adminMenu: {
            id: "2",
            menuName: "执法申请",
            menuType: "list",
            path: "11",
            icon: null,
          },
          children: [
            {
              adminMenu: {
                id: "5",
                menuName: "申请列表",
                menuType: "list",
                path: "/tableApproval",
                icon: null,
              },
              children: [],
            },
          ],
        },
        {
          adminMenu: {
            id: "4",
            menuName: "部门管理",
            menuType: "page",
            path: "/tablejurisdiction",
            icon: null,
          },
          children: [],
        },
        {
          adminMenu: {
            id: "7",
            menuName: "企业",
            menuType: "list",
            path: "2",
            icon: null,
          },
          children: [
            {
              adminMenu: {
                id: "8",
                menuName: "企业评价",
                menuType: "page",
                path: "/tableenterprise",
                icon: null,
              },
              children: [],
            },
            {
              adminMenu: {
                id: "9",
                menuName: "企业管理",
                menuType: "page",
                path: "/tableAdministration",
                icon: null,
              },
              children: [],
            },
          ],
        },
        // 乡镇管理
        {
          adminMenu: {
            id: "4",
            menuName: "乡镇管理",
            menuType: "page",
            path: "/tablecountry",
            icon: null,
          },
          children: [],
        },
        // 执法管理
        {
          adminMenu: {
            id: "10",
            menuName: "执法管理",
            menuType: "list",
            path: "3",
            icon: null,
          },
          children: [
            {
              adminMenu: {
                id: "11",
                menuName: "执法异常列表",
                menuType: "page",
                path: "/tableAbnormal",
                icon: null,
              },
              children: [],
            },
            {
              adminMenu: {
                id: "12",
                menuName: "执法数据公开",
                menuType: "page",
                path: "/tableoption",
                icon: null,
              },
              children: [],
            },
            {
              adminMenu: {
                id: "13",
                menuName: "执法记录管理",
                menuType: "page",
                path: "/tableApprovalAdmin",
                icon: null,
              },
              children: [],
            },
          ],
        },
        // 消息推送
        {
          adminMenu: {
            id: "14",
            menuName: "信息推送",
            menuType: "list",
            path: "4",
            icon: null,
          },
          children: [
            {
              adminMenu: {
                id: "17",
                menuName: "惠企政策",
                menuType: "page",
                path: "/tablenewsbuild",
                icon: null,
              },
              children: [],
            },
          ],
        },
        // 系统设置
        {
          adminMenu: {
            id: "14",
            menuName: "系统设置",
            menuType: "list",
            path: "99",
            icon: null,
          },
          children: [
            {
              adminMenu: {
                id: "18",
                menuName: "新建字典",
                menuType: "page",
                path: "/tablenewlable",
                icon: null,
              },
              children: [],
            },
            {
              adminMenu: {
                id: "19",
                menuName: "新建字典类型",
                menuType: "page",
                path: "/tablenewlable2",
                icon: null,
              },
              children: [],
            },
          ],
        },
      ],
      list2: [
        {
          adminMenu: {
            id: "1",
            menuName: "首页",
            menuType: "page",
            path: "/",
            icon: null,
          },
          children: [],
        },
        {
          adminMenu: {
            id: "2",
            menuName: "人员",
            menuType: "list",
            path: "1",
            icon: null,
          },
          children: [
            {
              adminMenu: {
                id: "3",
                menuName: "人员管理",
                menuType: "page",
                path: "/tablepersonnel",
                icon: null,
              },
              children: [],
            },
          ],
        },
        {
          adminMenu: {
            id: "2",
            menuName: "执法申请",
            menuType: "list",
            path: "11",
            icon: null,
          },
          children: [
            {
              adminMenu: {
                id: "5",
                menuName: "申请列表",
                menuType: "list",
                path: "/tableApproval",
                icon: null,
              },
              children: [],
            },
          ],
        },
        // {
        //   adminMenu: {
        //     id: "7",
        //     menuName: "企业",
        //     menuType: "list",
        //     path: "2",
        //     icon: null,
        //   },
        //   children: [
        //     {
        //       adminMenu: {
        //         id: "8",
        //         menuName: "企业评价",
        //         menuType: "page",
        //         path: "/tableenterprise",
        //         icon: null,
        //       },
        //       children: [],
        //     },
        //     {
        //       adminMenu: {
        //         id: "9",
        //         menuName: "企业管理",
        //         menuType: "page",
        //         path: "/tableAdministration",
        //         icon: null,
        //       },
        //       children: [],
        //     },
        //   ],
        // },
        // 乡镇管理
        // {
        //   adminMenu: {
        //     id: "4",
        //     menuName: "乡镇管理",
        //     menuType: "page",
        //     path: "/tablecountry",
        //     icon: null,
        //   },
        //   children: [],
        // },
        // 执法管理
        {
          adminMenu: {
            id: "10",
            menuName: "执法管理",
            menuType: "list",
            path: "3",
            icon: null,
          },
          children: [
            {
              adminMenu: {
                id: "12",
                menuName: "执法数据公开",
                menuType: "page",
                path: "/tableoption",
                icon: null,
              },
              children: [],
            },
            {
              adminMenu: {
                id: "13",
                menuName: "执法记录管理",
                menuType: "page",
                path: "/tableApprovalAdmin",
                icon: null,
              },
              children: [],
            },
          ],
        },
      ],
      list3: [
        {
          adminMenu: {
            id: "1",
            menuName: "首页",
            menuType: "page",
            path: "/",
            icon: null,
          },
          children: [],
        },
        {
          adminMenu: {
            id: "2",
            menuName: "人员",
            menuType: "list",
            path: "1",
            icon: null,
          },
          children: [
            {
              adminMenu: {
                id: "3",
                menuName: "人员管理",
                menuType: "page",
                path: "/tablepersonnel",
                icon: null,
              },
              children: [],
            },
          ],
        },
        {
          adminMenu: {
            id: "2",
            menuName: "执法申请",
            menuType: "list",
            path: "11",
            icon: null,
          },
          children: [
            {
              adminMenu: {
                id: "55",
                menuName: "副局长申请列表",
                menuType: "list",
                path: "/tableApproval2",
                icon: null,
              },
              children: [],
            },
          ],
        },
        // 执法管理
        {
          adminMenu: {
            id: "10",
            menuName: "执法管理",
            menuType: "list",
            path: "3",
            icon: null,
          },
          children: [
            {
              adminMenu: {
                id: "12",
                menuName: "执法数据公开",
                menuType: "page",
                path: "/tableoption",
                icon: null,
              },
              children: [],
            },
            {
              adminMenu: {
                id: "13",
                menuName: "执法记录管理",
                menuType: "page",
                path: "/tableApprovalAdmin",
                icon: null,
              },
              children: [],
            },
          ],
        },
      ],
    };
  },

  // 检测路由变化
  watch: {
    $route() {
      this.setCurrentRoute();
    },
  },

  methods: {
    handleOpen(key, keyPath) {
      // console.log(key, keyPath, 111111);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath, 222222);
    },
    // 跳转路由
    navclick(v) {
      // console.log(v);
      this.$emit("addtab", v);
      this.$router.push(v.path);
    },
    // 查看路由
    setCurrentRoute() {
      this.activeIndex = this.$route.path; // 通过他就可以监听到当前路由状态并激活当前菜单
    },
    seedata() {},
  },
  mounted() {
    Getadmininfo().then((res) => {
      // console.log(res);
      if (res.code == 200) {
        this.grade = res.data.user.roleId;
        // console.log(this.grade);
        if (this.grade == 1) {
          this.navlist = this.list1;
        } else if (this.grade == 9) {
          this.navlist = this.list2;
        } else if (this.grade == 2) {
          this.navlist = this.list3;
        }
      }
    });
    this.setCurrentRoute();
  },
};
</script>

<style lang="scss" scoped>
.el-menu {
  width: 199px !important;
}
.el-menu--inline > .el-menu-item {
  background-color: #4b5259 !important;
  &:hover {
    background-color: #343434 !important;
  }
}
.el-menu-item-group__title {
  padding: 0 !important;
}
</style>