import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { read, utils } from "xlsx"; // 注意处理方法引入方式
Vue.use(ElementUI);
ElementUI.Dialog.props.closeOnClickModal.default = false;
Vue.config.productionTip = false
//全局引入echarts
import * as echarts from 'echarts';
//需要挂载到Vue原型上
Vue.prototype.$echarts = echarts

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.use(Viewer) 

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')



import tinymce from 'tinymce'
import VueTinymce from '@packy-tang/vue-tinymce'
 
//tinymce样式
import 'tinymce/skins/content/default/content.min.css'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/content.min.css'
 
//tinymce主题
import 'tinymce/themes/silver'
 
//tinymce插件
import 'tinymce/plugins/link' //链接插件
import 'tinymce/plugins/image' //图片插件
import 'tinymce/plugins/media' //媒体插件
import 'tinymce/plugins/table' //表格插件
import 'tinymce/plugins/lists' //列表插件
import 'tinymce/plugins/quickbars' //快速栏插件
import 'tinymce/plugins/fullscreen' //全屏插件
 
/**
 * 注：
 * 5.3.x版本需要额外引进图标，没有所有按钮就会显示not found
 */
import 'tinymce/icons/default/icons'
 
//本地化
import './utils/tinymce/langs/zh_CN.js'
 
Vue.prototype.$tinymce = tinymce
Vue.use(VueTinymce)