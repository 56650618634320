import request from "./request"
// 左侧导航树状结构
export const GetMenuTreeList = (params) => request.get('/api/menu/treeList', {
  params
})

// 获取所有人员列表​
export const Getadminlist = (params) => request.get('/api/admin/list', {
  params
})

// 获取指定角色用户列表
// export function GetuserList(data) {
//   return request({
//     url: '/api/admin/userList',
//     method: 'get',
//     data
//   })
// }
export const GetuserList = (params) => request.get('/api/admin/userList', {
  params
})

// 修改指定用户信息
export function Postupdate(data) {
  return request({
    url: '/api/admin/update/' + data.id,
    method: 'post',
    data
  })
}
// 删除指定用户信息
export function Postdelete(data) {
  return request({
    url: '/api/admin/delete/' + data,
    method: 'post',
    data
  })
}
//添加用户
export function Postregister(data) {
  return request({
    url: '/api/admin/register',
    method: 'post',
    data
  })
}
// 修改用户密码
export function PostupdatePassword(data) {
  return request({
    url: '/api/admin/updatePassword',
    method: 'post',
    data
  })
}

// 重置用户密码
export function PostresetPassword(data) {
  return request({
    url: '/api/admin/resetPassword',
    method: 'post',
    data
  })
}

// 查看指定用户的角色
export function Postroleid(data) {
  return request({
    url: '/api/admin/role/' + data,
    method: 'get',
    data
  })
}

// 指定用户分配角色
export function Postroleupdate(query) {
  return request({
    url: '/api/admin/role/update',
    method: 'post',
    params: query
  })
}

// 获取所有所有角色
export function PostrolelistAll(data) {
  return request({
    url: '/api/role/listAll',
    method: 'get',
    data
  })
}


// 登录
export function PostLogin(data) {
  return request({
    url: '/api/admin/login',
    method: 'post',
    data
  })
}

// 获取当前登录用户信息 
export function Getadmininfo(data) {
  return request({
    url: '/api/admin/info',
    method: 'get',
    data
  })
}

// 获取所有企业/搜索
export function GetumsCompany(query) {
  return request({
    url: '/api/ums/umsCompany/list',
    method: 'get',
    params: query
  })
}

// 添加企业数据
export function PostumsCompany(data) {
  return request({
    url: '/api/ums/umsCompany/save',
    method: 'post',
    data
  })
}

// 修改企业数据
export function PutumsCompanyupdate(data) {
  return request({
    url: '/api/ums/umsCompany/update',
    method: 'put',
    data
  })
}

// 删除企业信息
export function DeleteumsCompany(query) {
  return request({
    url: '/api/ums/umsCompany/delete',
    method: 'delete',
    params: query
  })
}

// 批量上传企业信息
export function PostimportCompany(data) {
  return request({
    url: '/api/ums/umsCompany/importCompany',
    method: 'post',
    data
  })
}

// 导出企业
export function GetexportCompany(query) {
  return request({
    url: '/api/ums/umsCompany/exportCompany',
    method: 'get',
    responseType: 'blob',
    params: query
  })
}

//导出模板
export function GetexportTemplate(query) {
  return request({
    url: '/api/ums/umsCompany/exportTemplate',
    method: 'get',
    responseType: 'blob',
    params: query
  })
}

// 导出执法人员模板
export function GetadminexportTemplate(query) {
  return request({
    url: '/api/admin/exportTemplate',
    method: 'get',
    responseType: 'blob',
    params: query
  })
}

// 批量上传执法人员
export function PostadminimportUser(data) {
  return request({
    url: '/api/admin/importUser',
    method: 'post',
    data
  })
}

// 导出二维码
export function PostdownloadQrCode(data) {
  return request({
    url: '/api/ums/umsCompany/downloadQrCode',
    method: 'post',
    responseType: 'blob',
    data
  })
}

// 获取所有部门
export function GetsysDept(query) {
  return request({
    url: '/api/sys/sysDept/list',
    method: 'get',
    params: query
  })
}

// 添加部门
export function PostsysDept(data) {
  return request({
    url: '/api/sys/sysDept/save',
    method: 'post',
    data
  })
}

// 删除部门
export function DeletesysDept(query) {
  return request({
    url: '/api/sys/sysDept/delete',
    method: 'delete',
    params: query
  })
}

// 编辑部门
export function PutsysDeptupdate(data) {
  return request({
    url: '/api/sys/sysDept/update',
    method: 'put',
    data
  })
}

// 部门对应企业列表
export function getCompanyByDeptId(query) {
  return request({
    url: '/api/sys/sysDeptCompanyRelation/getCompanyByDeptId',
    method: 'get',
    params: query
  })
}

// 添加部门归属企业 
export function PostsaveRelation(data) {
  return request({
    url: '/api/sys/sysDeptCompanyRelation/saveRelation',
    method: 'post',
    data
  })
}

// 删除部门归属企业 
export function PostremoveRelation(data) {
  return request({
    url: '/api/sys/sysDeptCompanyRelation/removeRelation',
    method: 'post',
    data
  })
}

// 查询乡镇列表
export function getsysCountrylist(query) {
  return request({
    url: '/api/sys/sysCountry/list',
    method: 'get',
    params: query
  })
}

// 添加乡镇数据 
export function PostsysCountrysave(data) {
  return request({
    url: '/api/sys/sysCountry/save',
    method: 'post',
    data
  })
}

// 删除乡镇数据 
export function DeletesysCountry(query) {
  return request({
    url: '/api/sys/sysCountry/delete',
    method: 'delete',
    params: query
  })
}

// 编辑乡镇信息 
export function PutsysCountryupdate(data) {
  return request({
    url: '/api/sys/sysCountry/update',
    method: 'put',
    data
  })
}

// 批量增加乡镇信息 
export function PostbatchImport(data) {
  return request({
    url: '/api/sys/sysCountry/batchImport',
    method: 'post',
    data
  })
}

// 导出乡镇表格
export function GetexportCountry(query) {
  return request({
    url: '/api/sys/sysCountry/exportCountry',
    method: 'get',
    responseType: 'blob',
    params: query
  })
}

// 导出乡镇模板
export function GetexportTemplates(query) {
  return request({
    url: '/api/sys/sysCountry/exportTemplate',
    method: 'get',
    responseType: 'blob',
    params: query
  })
}

// 审批列表
export function GetmyApprovalList(query) {
  return request({
    url: '/api/sys/sysLawEnforcementApply/myApprovalList',
    method: 'get',
    params: query
  })
}

// 审批
export function Postapproval(data) {
  return request({
    url: '/api/sys/sysLawEnforcementApply/approval',
    method: 'post',
    data
  })
}

//企业诉求
export function GetsysCompanyIdea(query) {
  return request({
    url: '/api/sys/sysCompanyIdea/getIdea',
    method: 'get',
    params: query
  })
}

// 投诉举报 
export function GetsysCompanyComplaint(query) {
  return request({
    url: '/api/sys/sysCompanyComplaint/getComplaint',
    method: 'get',
    params: query
  })
}

// 删除评价
export function DeletedeleteById(query) {
  return request({
    url: '/api/sys/sysCompanyIdea/deleteById',
    method: 'delete',
    params: query
  })
}

// 来访人员 
export function GetsysVisitorsRecord(query) {
  return request({
    url: '/api/sys/sysVisitorsRecord/list',
    method: 'get',
    params: query
  })
}

// 删除来访人员  
export function DeleteRecorddeleteById(query) {
  return request({
    url: '/api/sys/sysVisitorsRecord/deleteById',
    method: 'delete',
    params: query
  })
}

// 新建标签类型 
export function GetsysDictType(query) {
  return request({
    url: '/api/sys/sysDictType/listType',
    method: 'get',
    params: query
  })
}

// 添加标签类型
export function PostsaveType(data) {
  return request({
    url: '/api/sys/sysDictType/saveType',
    method: 'post',
    data
  })
}

// 编辑标签类型 
export function PutsysupdateType(data) {
  return request({
    url: '/api/sys/sysDictType/updateType',
    method: 'put',
    data
  })
}

// 删除标签类型 
export function DeletesysDictType(query) {
  return request({
    url: '/api/sys/sysDictType/deleteType',
    method: 'delete',
    params: query
  })
}

// 查看标签列表 
export function GetsysDictTypelistData(query) {
  return request({
    url: '/api/sys/sysDictType/listData',
    method: 'get',
    params: query
  })
}

// 添加标签列表 
export function PostsysDictTypesaveData(data) {
  return request({
    url: '/api/sys/sysDictType/saveData',
    method: 'post',
    data
  })
}

// 编辑标签 
export function PutsysDictTypeupdateData(data) {
  return request({
    url: '/api/sys/sysDictType/updateData',
    method: 'put',
    data
  })
}

// 删除标签 
export function DeletesysDictTypedeleteData(query) {
  return request({
    url: '/api/sys/sysDictType/deleteData',
    method: 'delete',
    params: query
  })
}

// 新闻列表 
export function GetsysNewsManager(query) {
  return request({
    url: '/api/sys/sysNewsManager/list',
    method: 'get',
    params: query
  })
}

// 添加新闻 
export function PostsysNewsManagersave(data) {
  return request({
    url: '/api/sys/sysNewsManager/save',
    method: 'post',
    data
  })
}

// 删除新闻 
export function DeletesysNewsManagerdeleteById(query) {
  return request({
    url: '/api/sys/sysNewsManager/deleteById',
    method: 'delete',
    params: query
  })
}

// 编辑新闻 
export function PutsysNewsManagerupdate(data) {
  return request({
    url: '/api/sys/sysNewsManager/update',
    method: 'put',
    data
  })
}

//上架新闻 
export function PutsysNewsManagernewsUp(query) {
  return request({
    url: '/api/sys/sysNewsManager/newsUp',
    method: 'put',
    params: query
  })
}

// 下架新闻 
export function PutsysNewsManagernewsDown(query) {
  return request({
    url: '/api/sys/sysNewsManager/newsDown',
    method: 'put',
    params: query
  })
}

// 执法数据公开列表 
export function GetsysLawEnforcementRecord(query) {
  return request({
    url: '/api/sys/sysLawEnforcementRecord/list',
    method: 'get',
    params: query
  })
}

// 执法异常列表 
export function GetsysLawEnforcementExceptionRecord(query) {
  return request({
    url: '/api/sys/sysLawEnforcementExceptionRecord/list',
    method: 'get',
    params: query
  })
}

// 删除异常执法 
export function DeletesysLawEnforcementExceptionRecord(query) {
  return request({
    url: '/api/sys/sysLawEnforcementExceptionRecord/delete',
    method: 'delete',
    params: query
  })
}

// 上传接口 
export function Postsysfileupload(data) {
  return request({
    url: '/api/sys/file/upload',
    method: 'post',
    data
  })
}

// 企业纪录 
export function GetsysCompanyReportLawRecordlist(query) {
  return request({
    url: '/api/sys/sysCompanyReportLawRecord/list',
    method: 'get',
    params: query
  })
}

// 审批列表 
export function GetsysLawEnforcementRecordmyApprovalList(query) {
  return request({
    url: '/api/sys/sysLawEnforcementRecord/myApprovalList',
    method: 'get',
    params: query
  })
}

// 执法审批 
export function PostsysLawEnforcementRecordapproval(data) {
  return request({
    url: '/api/sys/sysLawEnforcementRecord/approval',
    method: 'post',
    data
  })
}
 
// 大屏指标
export function GetanalysisdataIndex(query) {
  return request({
    url: '/api/sys/analysis/dataIndex',
    method: 'get',
    params: query
  })
}

// 折线图扫码  
export function GetscanQrCodeTrend(query) {
  return request({
    url: '/api/sys/analysis/scanQrCodeTrend',
    method: 'get',
    params: query
  })
}

// 柱状图执法人员
export function GetrecordTrend(query) {
  return request({
    url: '/api/sys/analysis/recordTrend',
    method: 'get',
    params: query
  })
}

// 申请数量趋势 面积图
export function GetapplyTrend(query) {
  return request({
    url: '/api/sys/analysis/applyTrend',
    method: 'get',
    params: query
  })
}

// 部门执法柱状图 
export function GetlawEnforcementTrend(query) {
  return request({
    url: '/api/sys/analysis/lawEnforcementTrend',
    method: 'get',
    params: query
  })
}


// 执法预警占比 饼图
export function GetpieMonth(query) {
  return request({
    url: '/api/sys/analysis/pieMonth',
    method: 'get',
    params: query
  })
}

// 导出执法记录
export function GetexportMyApprovalList(query) {
  return request({
    url: '/api/sys/sysLawEnforcementRecord/exportMyApprovalList',
    method: 'get',
    responseType: 'blob',
    params: query
  })
}
