<template>
  <div class="index">
    <el-container>
      <el-header
        ><div class="header-content">
          <p class="header-tit">阳光执法</p>
          <div class="user-info">
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                {{ use }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="1">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-header>
      <el-container>
        <el-aside><Nav @addtab="addtab"></Nav></el-aside>
        <el-main>
          <div style="margin-bottom: 10px">
            <!-- <el-breadcrumb separator="/">
              <el-breadcrumb-item
                v-for="(item, index) in tablist"
                :key="index"
                :to="{ path: item.path }"
                >{{ item.title }}</el-breadcrumb-item
              >
            </el-breadcrumb> -->
            <el-tabs
              v-model="editableTabsValue"
              type="card"
              @tab-remove="removeTab"
              @tab-click="tiao"
            >
              <el-tab-pane
                v-for="item in editableTabs"
                :key="item.name"
                :label="item.title"
                :name="item.name"
                :to="{ path: item.path }"
                :closable="item.closable"
              >
              </el-tab-pane>
            </el-tabs>
          </div>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Nav from "../components/Nav/Nav.vue";
import { GetMenuTreeList, Getadmininfo } from "@/request/api";
export default {
  data() {
    return {
      tablist: [{ title: "首页", path: "/" }],
      bool: true,
      use: "",
      editableTabsValue: "1",
      editableTabs: [
        { title: "首页", name: "1", content: "/", closable: false },
      ],
      tabIndex: 1,
    };
  },
  components: {
    Nav,
  },
  methods: {
    addtab(data) {
      this.editableTabs.map((item, index) => {
        if (data.menuName == item.title) {
          this.editableTabsValue = item.name;
          this.bool = false;
        }
      });
      if (this.bool) {
        let newTabName = ++this.tabIndex + "";
        this.editableTabs.push({
          title: data.menuName,
          name: newTabName,
          content: data.path,
          closable: true,
        });
        this.editableTabsValue = newTabName;
      } else {
        this.bool = true;
      }
    },

    handleCommand(command) {
      // console.log(command);
      if (command == 1) {
        localStorage.clear();
        this.$router.push("/login");
      }
    },
    // addtab(v) {
    //   console.log(v);
    //   let newTabName = ++this.tabIndex + "";
    //   this.editableTabs.push({
    //     title: v.menuName,
    //     name: newTabName,
    //     content: v.path,
    //   });
    //   this.editableTabsValue = newTabName;
    // },
    removeTab(targetName) {
      let tabs = this.editableTabs;
      let activeName = this.editableTabsValue;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
              this.$router.push(nextTab.content);
            }
          }
        });
      }
      this.editableTabsValue = activeName;
      this.editableTabs = tabs.filter((tab) => tab.name !== targetName);
      // this.editableTabs.map((item, index) => {
      //   if (item.content == targetName) {
      //     this.editableTabs.splice(index, 1);
      //   }
      // });
    },
    tiao(v) {
      // console.log(v);
      // this.$router.push(v.content);
      this.editableTabs.map((item, index) => {
        if (item.name == v.name) {
          this.$router.push(item.content);
        }
      });
    },
  },
  mounted() {
    Getadmininfo().then((res) => {
      // console.log(res);
      if (res.code == 200) {
        this.use = res.data.user.nickName;
      }
    });
    if (this.$route.path != "/") {
      this.editableTabs.map((item, index) => {
        if (this.$route.meta.title == item.title) {
          this.bool = false;
        }
      });
      if (this.bool) {
        let newTabName = ++this.tabIndex + "";
        this.editableTabs.push({
          title: this.$route.meta.title,
          name: newTabName,
          content: this.$route.path,
        });
        this.editableTabsValue = newTabName;
      } else {
        this.bool = true;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.index {
  width: 100%;
  height: 100vh;
}
.el-header,
.el-footer {
  background-color: #1f2d3d;
  color: #333;
  // text-align: center;
  // line-height: 60px;
}

.el-aside {
  background-color: #545c64;
  color: #333;
  text-align: left;
  // line-height: 200px;
  height: calc(100vh - 60px);
  width: 199px !important;
  overflow: auto !important;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 0px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #535353;
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #ededed;
  }
}

.el-main {
  background-color: #fff;
  color: #333;
  text-align: left;
  height: calc(100vh - 60px);
  padding-bottom: 200px;
  // line-height: 160px;
}

body > .el-container {
  margin-bottom: 40px;
}

// .el-container:nth-child(5) .el-aside,
// .el-container:nth-child(6) .el-aside {
//   line-height: 260px;
// }

// .el-container:nth-child(7) .el-aside {
//   line-height: 320px;
// }
.header-content {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  .header-tit {
    font-size: 18px;
    font-weight: bold;
  }
  .user-info {
    display: flex;
    align-items: center;
    .el-dropdown-link {
      display: flex;
      align-items: center;
      img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .header-username {
        font-size: 16px;
        color: #fff;
      }
    }
  }
}
</style>