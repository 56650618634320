import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'index',
    component: Index,
    children: [{
        path: "/",
        name: "home",
        meta: {
          title: '首页'
        },
        component: () => import( /* webpackChunkName: "table" */ "../views/home/index.vue")
      },
      {
        path: "/tablepersonnel",
        name: "tablepersonnel",
        meta: {
          title: '人员管理'
        },
        component: () => import( /* webpackChunkName: "table" */ "../views/Table/tablepersonnel.vue")
      },
      {
        path: "/tablejurisdiction",
        name: "tablejurisdiction",
        meta: {
          title: '部门管理'
        },
        component: () => import( /* webpackChunkName: "table" */ "../views/Table/tablejurisdiction.vue")
      },
      {
        path: "/tableApproval",
        name: "tableApproval",
        meta: {
          title: '申请列表'
        },
        component: () => import( /* webpackChunkName: "tableApproval" */ "../views/Table/tableApproval.vue")
      },
      {
        path: "/tableApproval2",
        name: "tableApproval2",
        meta: {
          title: '副局长审批'
        },
        component: () => import( /* webpackChunkName: "tableApproval" */ "../views/Table/tableApproval2.vue")
      },
      {
        path: "/tableenterprise",
        name: "tableenterprise",
        meta: {
          title: '企业评价'
        },
        component: () => import( /* webpackChunkName: "tableenterprise" */ "../views/Table/tableenterprise.vue")
      },
      {
        path: "/tableAdministration",
        name: "tableAdministration",
        meta: {
          title: '企业管理'
        },
        component: () => import( /* webpackChunkName: "tableAdministration" */ "../views/Table/tableAdministration.vue")
      },
      {
        path: "/tableCountry",
        name: "tablecountry",
        meta: {
          title: '乡镇管理'
        },
        component: () => import( /* tableCountry: "tablecountry" */ "../views/country/tablecountry.vue")
      },
      {
        path: "/tableAbnormal",
        name: "tableAbnormal",
        meta: {
          title: '执法异常列表'
        },
        component: () => import( /* webpackChunkName: "tableAbnormal" */ "../views/LawEnforcement/tableAbnormal.vue")
      },
      {
        path: "/tableoption",
        name: "tableoption",
        meta: {
          title: '执法数据公开'
        },
        component: () => import( /* webpackChunkName: "tableoption" */ "../views/LawEnforcement/tableoption.vue")
      },
      {
        path: "/tableApprovalAdmin",
        name: "tableApprovalAdmin",
        meta: {
          title: '执法记录管理'
        },
        component: () => import( /* webpackChunkName: "tableApprovalAdmin" */ "../views/Approval/tableApprovalAdmin.vue")
      },
      // {
      //   path: "/tablenewsenterprise",
      //   name: "tablenewsenterprise",
      //   meta: {
      //     title: '企业标签'
      //   },
      //   component: () => import( /* webpackChunkName: "tablenewsenterprise" */ "../views/news/tablenewsenterprise.vue")
      // },
      // {
      //   path: "/tablearticle",
      //   name: "tablearticle",
      //   meta: {
      //     title: '新闻标签'
      //   },
      //   component: () => import( /* webpackChunkName: "tablearticle" */ "../views/news/tablearticle.vue")
      // },
      {
        path: "/tablenewsbuild",
        name: "tablenewsbuild",
        meta: {
          title: '惠企政策'
        },
        component: () => import( /* webpackChunkName: "tablenewsbuild" */ "../views/news/tablenewsbuild.vue")
      },
      {
        path: "/tablenewlable",
        name: "tablenewlable",
        meta: {
          title: '新建标签'
        },
        component: () => import( /* webpackChunkName: "tablenewlable" */ "../views/news/tablenewlable.vue")
      },
      {
        path: "/tablenewlable2",
        name: "tablenewlable2",
        meta: {
          title: '新建标签类型'
        },
        component: () => import( /* webpackChunkName: "tablenewlable2" */ "../views/news/tablenewlable2.vue")
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route Approval
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "login" */ '../views/Login/login.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

// 路由守卫
router.beforeEach((to, from, next) => {
  //   //  to 到哪里去  from从哪里来
  //  判断登录状态 
  let token = localStorage.getItem('token')
  // let token = false;
  if (token) {
    next()
  } else {
    setTimeout(() => {
      next('/login');
    }, 100)
    next();

  }
  return;
})


export default router